import { useEffect, useMemo, useState } from 'react'
import { QueryResult } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useRouter } from 'next/router'
import { isStreaming } from '@/services/PhaseManagerService'
import { Episode, getPreferredSeason, Project, Season } from '@/services/ProjectsService'
import { getSeasonByNameOrIndex } from '@/services/ProjectsService/ProjectsService'
import { isARecap, isASneakPeek } from '@/utils/EpisodeUtil'
import { getItemOrFirstEntry } from '@/utils/array-utils'

interface useWatchProjectDataProps {
  // TODO make this the correct type
  data: QueryResult['data']
  skip?: boolean
  isGuildMember?: boolean
  isGeoblockedProject?: boolean
}

//TODO Re-rendering more than 15 times - figure out
export const useWatchProjectData = ({
  data,
  skip,
  isGuildMember = false,
  isGeoblockedProject,
}: useWatchProjectDataProps) => {
  const router = useRouter()

  const seasonNameOrIndex = getItemOrFirstEntry(router.query.season)
  const project = data
  const isTitleStreaming = isStreaming(data?.primaryFlowPhases)
  const seasons = project?.seasons?.filter(Boolean) as Season[] | undefined
  const [preferredSeason, setPreferredSeason] = useState<Season>()

  useEffect(() => {
    if (!project || !router.isReady) {
      return
    }

    if (seasonNameOrIndex) {
      setPreferredSeason(getSeasonByNameOrIndex({ seasonNameOrIndex, project }))
    } else {
      const preferredSeason = getPreferredSeason(project)
      setPreferredSeason(preferredSeason)
    }
  }, [data, project, router, seasonNameOrIndex, seasons])

  let currentEpisode: Episode | undefined = undefined
  const lastWatched = project?.continueWatching?.guid

  if (lastWatched) {
    seasons?.forEach((season) => {
      season?.episodes?.forEach((episode) => {
        if (episode?.guid === lastWatched) {
          currentEpisode = episode
        }
      })
    })
  }

  if (!currentEpisode) {
    currentEpisode = returnDefaultEpisode(seasons, isGuildMember, project, isTitleStreaming, isGeoblockedProject)
  }

  return useMemo(() => {
    if (skip) {
      return {
        data,
      }
    }

    return {
      data,
      preferredSeason,
      currentEpisode,
    }
  }, [skip, data, preferredSeason, currentEpisode])
}

function returnDefaultEpisode(
  seasons: Season[] | undefined,
  isGuildMember: boolean,
  project: Maybe<Project>,
  isTitleStreaming: boolean,
  isGeoblockedProject?: boolean,
) {
  const recap =
    isGuildMember && project?.projectType === 'series'
      ? seasons?.[0]?.episodes?.find((episode) => isARecap(episode))
      : null
  const sneakPeek =
    !isGuildMember && project?.projectType === 'movie' && !isGeoblockedProject
      ? seasons?.[0]?.episodes?.find((episode) => isASneakPeek(episode))
      : null
  const trailer =
    isGuildMember &&
    project?.projectType === 'movie' &&
    !isTitleStreaming &&
    !seasons?.[0]?.episodes?.find((episode) => episode?.name?.toLowerCase().includes('movie'))
      ? seasons?.[0]?.episodes?.find((episode) => episode?.isTrailer)
      : null
  // Show Trailer for guild members as default if movie isn't streaming yet and no episode is marked as the movie
  const movie =
    isGuildMember && project?.projectType === 'movie' && isTitleStreaming
      ? seasons?.[0]?.episodes?.find((episode) => episode?.name?.toLowerCase().includes('movie'))
      : null
  // Account for if Project is a movie and media pipeline hasn't ordered them correctly
  return recap ?? sneakPeek ?? trailer ?? movie ?? seasons?.[0]?.episodes?.[0] ?? undefined
}
